<script>
import { Bar } from "vue-chartjs";

export default {
	extends: Bar,

	props: {
		labels: {
			type: Array,
		},
		datasets: {
			type: Array,
		},
		options: {
			type: Object,
		},
		height: {
			type: Number,
		},
	},

	mounted() {
		this.renderChart(
			{
				labels: this.labels,
				datasets: this.datasets,
			},
			this.options
		);
	},
};
</script>
